import React from "react";
import tw from "tailwind.macro";
import SEO from "../../components/seo";
import Nav from "../../components/Nav";
import styled from '@emotion/styled';
import Footer from "../../components/Footer";
import ContactSection from "../../components/ContactSection";
import heroImage from "../../images/hero-illustration 1.png";
import { css } from '@emotion/core';

const Wrapper = tw.div`
  flex items-center flex-col 
`;

const HeroText = styled('h1')`
  ${tw`sm:text-3xl text-themeGreen-700`};

  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 123.19%;
`;

const HeroSection = tw.div`
  w-full max-w-6xl items-center justify-between flex flex-row pt-8  
  sm:flex-col sm:px-4 
  flex justify-center items-center flex-col
`;

const HeroSubText = styled('h3')`
  ${tw`font-sans text-sm leading-relaxed py-4 font-light flex-grow mb-8`};
  max-width: 32rem;
  font-weight: 500;
  color: #586461;
`;

const HeroSubTextInner = styled('div')`
  ${tw`mt-4`};
`;

const CTAButton = tw.a`
  bg-themeGreen-500 border border-themeGreen-500 text-xs px-8 py-4 leading-none text-white mt-4 lg:mt-0
`;

const HeroImg = styled('img')`
  max-width: 20.625rem;
`;

export default () => (
  <Wrapper>
    <SEO title="Donation Confirmation" />
    <Nav />
    <HeroSection css={css`height: 75vh;`}>
      <HeroText>Thank you for your donation to Operation Masks and helping our healthcare and frontline workers. We sincerely appreciate it.</HeroText>
    </HeroSection>
    <Footer />
  </Wrapper>
)
